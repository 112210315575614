<template >
  <div>
    <div class="app-modal__box">
      <div class="app-modal__in">
        <div class="app-modal__header d-flex f-between p-5 m-title-modal">
          <div class="w-100">
            <el-row :gutter="20">
              <el-col :xs="24" :sm="24" :md="24" :lg="16" :xl="16">
                <p class="large--title m-0">{{
                    $t("message.new_m", {
                      m: $t("message.payment"),
                    })
                  }}
                  <span class="ml-3"><b> {{$t('message.client_balance')}}: {{ (parseFloat(remainderForClientBalance)+parseFloat(form.balance)) | formatMoney(2) }}</b></span>
                </p>
              </el-col>
              <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
                <crm-store-update-close
                  class="w-100  d-flex  f-end"
                  :permission="$options.name"
                  :button_type="'store'"
                  :loading="loadingButton"
                  @c-submit="beforeSubmit(true)"
                  @c-close="close()"
              ></crm-store-update-close>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
      <!-- app-modal__header end -->

      <div class="app-modal__body p-5 pb-0 mt-5">
        <div class="timeline-items__right rounded-sm w-100 p-4">
            <el-form ref="form" :model="form" :rules="rules" label-position="top">
                <el-row :gutter="20">
                    <el-col :span="8">
                      <el-form-item
                        prop="company_id"
                        :label="$t('message.company')"
                        >
                        <select-company
                            :size="'mini'"
                            :placeholder="columns.company_id.title"
                            :id="form.company_id"
                            v-model="form.company_id"
                            >
                        </select-company>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item
                      prop="client_id"
                      :label="$t('message.client')"
                      >
                        <select-client
                            :size="'mini'"
                            :company_id="form.company_id"
                            :placeholder="columns.client_id.title"
                            :id="form.client_id"
                            v-model="form.client_id"
                            >
                        </select-client>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item
                      prop="payment_type_id"
                      :label="$t('message.paymentType')"
                      >
                        <select-payment-type
                            :size="'mini'"
                            :placeholder="columns.payment_type_id.title"
                            :id="form.payment_type_id"
                            v-model="form.payment_type_id"
                            >
                        </select-payment-type>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row :gutter="20">
                    <el-col :span="8">
                      <el-form-item
                      prop="bank_account_id"
                      :label="$t('message.bankAccount')"
                      >
                          <select-bank-account
                              :size="'mini'"
                              :clearable="false"
                              :placeholder="columns.bank_account_id.title"
                              :id="form.bank_account_id"
                              v-model="form.bank_account_id"
                              @getAccount="getAccount"
                              >
                          </select-bank-account>
                      </el-form-item>
                    </el-col>
                    <el-col :span="4">
                      <el-form-item
                        prop="money_amount"
                        :label="$t('message.money_amount')"
                        >
                          <crm-money-input
                            v-model="form.money_amount"
                            :old="form.money_amount"
                            :size="'medium'"
                          >
                          </crm-money-input>
                      </el-form-item>
                    </el-col>

                    <el-col :span="4">
                      <el-form-item
                      :label="$t('message.currency')"
                      >
                         {{ bankAccount.currency ?  bankAccount.currency.code : ''}}
                      </el-form-item>
                    </el-col>
                    <el-col :span="4" v-if="bankAccount.currency">
                      <el-form-item
                      prop="currency_rate"
                      :label="$t('message.rate')"
                      >
                        <el-input
                          :disabled="bankAccount.currency ? (bankAccount.currency.active) : false"
                          type="number"
                          size="mini"
                          :placeholder="$t('message.rate')"
                          v-model="form.currency_rate">
                        </el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="4" v-if="bankAccount.currency">
                      <el-form-item
                      :label="$t('message.money_amount')"
                      >
                          <span>{{ form.currency_rate * form.money_amount  | formatMoney(2) }}</span>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row :gutter="20">
							<el-col :span="8">
								<el-form-item prop="payment_date" :label="$t('message.payment_date')">
									<el-date-picker
										v-model="form.payment_date"
										type="date"
										:placeholder="$t('message.payment_date')"
										style="width: 100%"
										:format="date_format"
										:value-format="date_format"
										size="small"
									>
									</el-date-picker>
								</el-form-item>
							</el-col>
                    <el-col :span="16">
                      <el-form-item
                      prop="comment"
                      :label="$t('message.comment')"
                      >
                          <el-input
                            type="textarea"
                            :autosize="{ minRows: 2 }"
                            :placeholder="$t('message.comment')"
                            v-model="form.comment">
                          </el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>

                    <!-- end-col -->
            </el-form>
        </div>
      </div>
      <div class="app-modal__body p-5 pb-0">
        <div class="timeline-items__right rounded-sm w-100 p-4">
            <div class="m-3">
              <el-button :client_id="form.client_id" @click="checkClient()" size="large" type="primary"  v-loading="loadingUnpaidDeals">{{$t('message.unpaidClientDeals')}}</el-button>
              <span class="ml-5">Вычисление:
                <el-switch
                  v-model="autoCalculate"
                  active-text="авто"
                  inactive-text="ручной">
                </el-switch>
              </span>
              <hr>
            </div>
            <div class="m-4  mobil-w-100">
              <span class="ml-3"><b> {{$t('message.total_amount')}}: {{ total_amount | formatMoney(2) }}</b></span>
              <span class="ml-5"><b> {{$t('message.paid_money')}}: {{ total_paid | formatMoney(2) }}</b></span>
              <span class="ml-5" :class="(total_paying > form.money_amount) ? 'danger-insufficient' : ''"><b> {{$t('message.paying_money')}}: {{ total_paying*form.currency_rate | formatMoney(2) }}</b></span>
              <span class="ml-5"><b> {{$t('message.remainder')}}: {{ (total_remainder>0 ? total_remainder : 0)| formatMoney(2) }}</b></span>
              <span class="ml-5"><b> {{$t('message.remainder_for_client_balance')}}: {{ (remainderForClientBalance>0 ? remainderForClientBalance : 0)  | formatMoney(2) }}</b></span>
            </div>
            <el-row v-loading="loadingUnpaidDeals || loadingData">
              <el-col :span="24">
                <el-table
                ref="multipleTable"
                :data="selectedDealsList"
                size="small"
                :row-class-name="tableRowClassName"
                @selection-change="handleSelectionChange"
                @select="dealSelected"
                style="width: 100%">
                    <el-table-column
                        type="selection"
                        width="55">
                    </el-table-column>
                    <el-table-column
                        width="60"
                        :label="$t('message.n')">
                        <template slot-scope="item">
                            {{ item.row.id }}
                        </template>
                    </el-table-column>
                    <el-table-column
                        :label="$t('message.deal')">
                        <template slot-scope="item">
                            {{ item.row.name}}
                        </template>
                    </el-table-column>
                    <el-table-column
                        :label="$t('message.begin_date')">
                        <template slot-scope="item">
                            {{ item.row.begin_date }}
                        </template>
                    </el-table-column>
                    <el-table-column
                        :label="$t('message.end_date')">
                        <template slot-scope="item">
                            {{ item.row.end_date }}
                        </template>
                    </el-table-column>
                    <el-table-column
                        :label="$t('message.dealStage')">
                        <template slot-scope="item">
                            {{ item.row.dealStage }}
                        </template>
                    </el-table-column>
                    <el-table-column
                        :label="$t('message.total_amount')">
                        <template slot-scope="item">
                            {{ item.row.money_amount | formatNumber(1) }}
                        </template>
                    </el-table-column>
                    <el-table-column
                        :label="$t('message.paid_money')">
                        <template slot-scope="item">
                            {{ item.row.paid_money | formatNumber(1) }}
                        </template>
                    </el-table-column>
                    <el-table-column
                        width="180"
                        :label="$t('message.paying_money')">
                        <template slot-scope="item">
                          <el-input
                            :placeholder="$t('message.please_enter_input', {input:$t('message.money_amount')})"
                            :size="'medium'"
                            v-model="item.row.current_payment"
                            type="number"
                            >
                          </el-input>
                        </template>
                    </el-table-column>
                    <el-table-column
                        :label="$t('message.remainder')">
                        <template slot-scope="item">
                            {{ calculate_remainder(item.row) | formatNumber(1) }}
                        </template>
                    </el-table-column>
                    <el-table-column
                        :label="$t('message.currency')">
                        <template slot-scope="item">
                            {{ item.row.currency }}
                        </template>
                    </el-table-column>
                    <el-table-column
                        :label="$t('message.rate')">
                        <template slot-scope="item">
                            {{ item.row.currency_rate }}
                        </template>
                    </el-table-column>
                    <el-table-column :label="$t('message.update')" width="70">
                        <template slot="header">
                          <i class="el-icon-setting"></i>
                        </template>
                        <template slot-scope="item">
                          <el-button @click="dealUpdate(item.row);" type="primary" size="mini" icon="el-icon-edit"></el-button>
                        </template>
                    </el-table-column>
                </el-table>
              </el-col>
            </el-row>
        </div>
      </div>
      <div class="app-modal app-modal__full bg-seriq">
        <el-drawer 
          ref="drawerUpdateDeal" 
          :with-header="false" 
          :class="mode ? 'l-modal-style' : 'd-modal-style'"
          class="create-task-modal"
          :append-to-body="true"
          @opened="drawerOpened('drawerUpdateDealChild')"
           @closed="drawerClosed('drawerUpdateDealChild')"
          :visible.sync="drawerUpdateDeal"
          size="96%">

          <AppModal 
            ref="drawerUpdateDealChild" 
            :selectedDeal="selectedDeal" 
            :dealCreate="false"
            @addModalEdit="drawerUpdateDeal = false" 
            drawer="drawerUpdateDeal" />

        </el-drawer>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import form from "@/utils/mixins/form";
import show from "@/utils/mixins/show";
import selectCompany from "@/components/inventory/select-company";
import selectClient from "@/components/selects/select-client";
import selectPaymentType from "@/components/inventory/select-paymentType";
import selectBankAccount from "@/components/inventory/select-bank-account";
import AppModal from "@/views/deals/components/add-modal";

export default {
  mixins: [form, drawer, show],
  components: {
    selectCompany,
    selectClient,
    selectPaymentType,
    selectBankAccount,
    AppModal
  },
  data() {
    return {
      bankAccount: {},
      drawerUpdateDeal: false,
      drawerUnpaidDeals: false,
      loadingUnpaidDeals: false,
      selectedDealsList: [],
      autoCalculate: false,
      paymentRemainder: 0,
      selectedDeal: {}
    };
  },
  created() {},
  computed: {
      ...mapGetters({
          mode: "MODE",
          rules: "payments/rules",
          model: "payments/model",
          columns: "payments/columns",
          unpaidList: "deals/unpaidDealsList",
      }),
      total_remainder(){
          return _.round(parseFloat((this.total_amount - this.total_paid - ((this.form.money_amount-this.paymentRemainder)*this.form.currency_rate))), 9);
      },
      total_amount(){
        if(this.selectedDealsList.length > 0){
          return parseFloat(this.selectedDealsList.reduce((a, b) => a + (parseFloat(b.money_amount * (b.currency_rate ? b.currency_rate : 1)) || 0), 0));
        }
        return 0;
      },
      total_paid(){
        if(this.selectedDealsList.length > 0){
          return parseFloat(this.selectedDealsList.reduce((a, b) => a + (parseFloat(b.paid_money * (b.currency_rate ? b.currency_rate : 1)) || 0), 0));
        }
        return 0;
      },
      total_paying(){
        if(this.selectedDealsList.length > 0){
          return _.round(parseFloat(this.selectedDealsList.reduce((a, b) => a + (parseFloat(b.current_payment) || 0), 0)), 9);
        }
        return 0;
      },
      remainderForClientBalance(){
          return _.round((this.form.money_amount - this.total_paying), 9)*this.form.currency_rate;
      }
  },
  watch: {
      bankAccount: {
          handler: function(newVal, oldVal) {
              if(this.bankAccount.currency && oldVal.currency){
                this.form.currency_rate = this.bankAccount.currency.rate;
              }
          },
          deep: true,
          immediate: true
      },
      "form.money_amount": {
          handler: function(newVal, oldVal) {
            if(newVal > 1){
              this.paymentsByDeals(this.selectedDealsList);
            }
          },
          deep: true,
          immediate: true
      },
      "form.currency_rate": {
          handler: function(newVal, oldVal) {
            if(this.form.money_amount > 1){
              this.paymentsByDeals(this.selectedDealsList);
            }
          },
          deep: true,
          immediate: true
      },
  },
  methods: {
      ...mapActions({
          save: "payments/update",
          show: "payments/show",
          updateUnpaidList: "deals/getUnpaidDeals",
      }),

      fetchData() {
          if (!this.loadingData) {
              this.loadingData=true
              this.show(this.selectedItem.id).then(res => {
                  this.loadingData = false;
                  this.updateUnpaidList({payment_id: this.form.id, client_id: this.form.client_id, get_current: true})
                    .then(resp =>{
                      this.selectedDealsList = JSON.parse(JSON.stringify(this.unpaidList));

                      this.$refs.multipleTable.toggleAllSelection(this.selectedDealsList, true);
                    });
              }).catch(err => {
                  this.loadingData = false
              });
          }
      },

      handleSelectionChange(val){
        this.paymentsByDeals(val);
        if(val.length === 0){
          this.selectedDealsList.map(item => item.current_payment = 0);
        }
        this.$refs.multipleTable.sort();
      },
      tableRowClassName({row, rowIndex}){
        let remainder = _.round(this.calculate_remainder(row), 2);
        if(remainder < 0){
          return 'danger-row';
        }
        if(remainder === 0){
          return 'success-row';
        }
        return '';
      },
      dealSelected(selection, row){
        if(selection.some(el => el.id !== row.id)){
          this.$set(row, 'current_payment', 0);
        }
      },
      calculate_remainder(item){
        let unpaid = _.round(parseFloat(item.money_amount - item.paid_money), 9)
        if(item.current_payment && item.current_payment > 0){
          unpaid = _.round(parseFloat(unpaid - (item.current_payment*this.form.currency_rate/item.currency_rate)), 9)
        }
        return _.round(parseFloat(unpaid), 9);
      },
      checkClient(){
        if(this.form.client_id){
          this.loadingUnpaidDeals = true;
          this.updateUnpaidList({payment_id: this.form.id, client_id: this.form.client_id})
            .then(res =>{
              this.loadingUnpaidDeals = false;
              this.unpaidList.forEach(element => {
                if(this.selectedDealsList.some(e => e.id === element.id)){
                }else{
                  let deal = JSON.parse(JSON.stringify(element));
                  this.$set(deal, 'current_payment', 0);
                  this.selectedDealsList.push(deal);
                }
              });
            }).catch(err => {
              this.loadingUnpaidDeals = false;
            });
        }else{
          this.$notify({
              title: this.$t('message.warning'),
              type: "warning",
              offset: 130,
              message: this.$t('message.please_select_input', {input: this.$t('message.client')})
          });
        }
      },

      paymentsByDeals(selectedList, renew_remainder = true){
        if(this.autoCalculate){
          if(renew_remainder){
            this.paymentRemainder = this.form.money_amount;
          }
          selectedList.forEach(element => {
            if(this.paymentRemainder > 0){
              this.$set(element, 'current_payment', 0);
              let current_payment = 0
              let total_amount_in_deal_currency = parseFloat(this.paymentRemainder * this.form.currency_rate/parseFloat(element.currency_rate));
              let total_unpaid = _.round(parseFloat(parseFloat(element.money_amount) - parseFloat(element.paid_money)), 9);
              if(total_amount_in_deal_currency >= total_unpaid){
                this.paymentRemainder -= _.round(parseFloat(total_unpaid*parseFloat(element.currency_rate)/this.form.currency_rate), 9);
                current_payment = _.round(parseFloat(total_unpaid*parseFloat(element.currency_rate)/this.form.currency_rate), 9);
              }else{
                current_payment = _.round(parseFloat(this.paymentRemainder), 9);
                this.paymentRemainder = 0;
              }
              this.$set(element, 'current_payment', current_payment);
              setTimeout(() => {
                this.$refs.multipleTable.toggleRowSelection(element, true);
              }, 100);
            }else{
              this.$set(element, 'current_payment', 0);
            }
          });
        }
      },

      getAccount(account){
          this.bankAccount = account;
      },
      afterLeave(){
        this.paymentRemainder = 0;
        this.autoCalculate = false;
        this.selectedClient = {};
        this.bankAccount = {};
        this.form = {};
        this.selectedDealsList = [];
      },
      removeFromPaid(index, deal){
        this.selectedDealsList.splice(index, 1);
      },
      beforeSubmit(){
        let success = true;
        for(const deal of this.selectedDealsList){
          if(_.round((deal.money_amount*deal.currency_rate - deal.current_payment*this.form.currency_rate), 9) < 0 || deal.current_payment < 0){
            success = false;
            break;
          }
        }

        let paymentsForDeals = this.selectedDealsList.map(el => { return {deal_id:el.id, current_payment:el.current_payment }});
        paymentsForDeals = paymentsForDeals.filter(el => el.current_payment > 0);
        this.form.paymentsForDeals = paymentsForDeals;
        if(success && this.total_paying <= this.form.money_amount && this.form.money_amount > 0){
          this.submit();
        }else if(!success){
          this.$notify({
              title: this.$t('message.error_m'),
              type: "error",
              offset: 130,
              message: this.$t('message.incorrect_money_for_deal')
          });
        }else if(this.total_paying > this.form.money_amount){
          this.$notify({
              title: this.$t('message.error_m'),
              type: "error",
              offset: 130,
              message: this.$t('message.insufficient_money')
          });
        }else if(this.form.money_amount <= 0){
          this.$notify({
              title: this.$t('message.error_m'),
              type: "error",
              offset: 130,
              message: this.$t('message.enter_money_correctly')
          });
        }
      },
      submit(close = true) {
          this.$refs["form"].validate((valid) => {
              if (valid) {
                  this.loadingButton = true;
                  this.save(this.form)
                      .then((res) => {
                          this.loadingButton = false;
                          this.$alert(res);
                          this.parent().listChanged();
                          if (close) this.close();
                      })
                      .catch((err) => {
                          this.loadingButton = false;
                          this.$alert(err);
                      });
              }
          });
      },

      dealUpdate(row){
        this.drawerUpdateDeal = true;
        this.selectedDeal = row;
      },
      drawerClosed(ref) {
        if (this.$refs[ref]) {
          this.$refs[ref].closed();
        }
        if (_.isFunction(this.empty)) {
          this.empty();
        }
      },
      drawerOpened(ref) {
        if (this.$refs[ref]) {
          if (_.isFunction(this.$refs[ref].opened)) {
            this.$refs[ref].opened();
          }
        }
      },
      closeDrawer(drawer) {
        if (this.$refs[drawer] && _.isFunction(this.$refs[drawer].closeDrawer)) {
          this.$refs[drawer].closeDrawer();
        }
      },
  },
};
</script>

<style>
.el-table .warning-row {
    background: rgb(245, 243, 142);
  }

  .el-table .success-row {
    background: #99da76;
  }
  .el-table .danger-row {
    background: #e9a5a9;
  }
  .danger-insufficient {
    color: #e6757c;
  }


</style>
